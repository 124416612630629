import currency from 'currency.js';
import _ from 'lodash';

const AUD = value => currency(value, { precision: 0, symbol: '$', formatWithSymbol: true  });
const shorterAUD = (value)=>{
    const aud = value=> currency(value, { precision: 1, symbol: '$', formatWithSymbol: true  })
    if(value>1000000){
        return `${aud(_.round(value / 1000000,1))}M`
    }else if(value>1000){
        return `${aud(_.round(value / 1000,0))}K`
    }else{
        return `${aud(_.round(value))}`
    }
}
const USD = value => currency(value, { precision: 2, symbol: '$' });
const Credit = value => currency(value, { precision: 2 });
const Percent = value => currency(value, { precision: 2,formatWithSymbol:true,separator:'', symbol:'%' ,pattern:'#!' });

function priceFormat(price) {
    const pattern = /[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)/;
    return (!_.isNumber(price) || pattern.test(price.toString())) ? price :
        ((Math.abs(price) > 0.01 || price === 0) ? (Math.abs(price) > 1000000 ? price.toPrecision(3) :
            price.toFixed(2)) : price.toPrecision(1));
}

function round(number, precision) {
    const pattern = /[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)/;
    return (!_.isNumber(number) || pattern.test(number.toString())) ? number : Math.round(+number + 'e' + precision) / Math.pow(10, precision);
}

function percentage(number, precision) {
    return (number * 100).toFixed(precision);
}

function numberWithCommas(number) {
    if (_.isNil(number)) { return number; }
    var parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

export { AUD, shorterAUD ,USD, Credit, priceFormat, round, percentage, numberWithCommas, Percent };