import {SheetsRegistry} from 'jss';
import {createGenerateClassName} from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

const light = {
    // typography:{
    //     fontFamily:'OpenSans'
    // },
    palette:{
        primary: {
            main: '#000000' //your color
        },
        clientPrimary:{
            main:'#fa6400',
            light:'#FEE7DA'
        },
        agentPrimary:{
            main:'#009999',
            light:'#E7F3F6'
        },
        greyPrimary:{
            main: 'rgba(0, 0, 0, 0.3)'
        },
        error:{
            main:'#DD0000'
        },
        alert:{
            main:'#F5B601'
        }
    },
    overrides: {
        // MuiCssBaseline: {
        //     '@global': {
        //       '@font-face': [OpenSans],
        //     },
        // },
        MuiButton: {
            text: {
                textTransform:'none',
            },
            outlined:{
                textTransform:'none',
            },
            contained:{
                textTransform:'none',
            }
        },
    },
    breakpoints:{
        values:{
            tablet:1681
        }
    }
}

const theme = createMuiTheme(light);

const createPageContext = () => ({
	theme,
	// This is needed in order to deduplicate the injection of CSS in the page.
	sheetsManager: new Map(),
	// This is needed in order to inject the critical CSS.
	sheetsRegistry: new SheetsRegistry(),
	// The standard class name generator.
	generateClassName: createGenerateClassName()
});

export default function getPageContext () {
	// Make sure to create a new context for every server-side request so that data
	// isn't shared between connections (which would be bad).
	if (!process.browser) {
		return createPageContext();
	}
	
	// Reuse context on the client-side.
	/* eslint-disable no-underscore-dangle */
	if (!global.__INIT_MATERIAL_UI__) {
		global.__INIT_MATERIAL_UI__ = createPageContext();
	}
	
	return global.__INIT_MATERIAL_UI__;
}

export {getPageContext,theme}