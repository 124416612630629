import { observable, action, computed, extendObservable } from 'mobx';

import remove from 'lodash/remove'

class DealStore {
    constructor(appStore){
        this.appStore = appStore
    }

    @observable isUploadPanelOpen = false

    @action setIsUploadPanelOpen = (value) => {

        this.isUploadPanelOpen = value
        // console.log(this.isUploadPanelOpen)
    }

    @action switchUploadPanel = () => {
        this.isUploadPanelOpen = this.isUploadPanelOpen ? false : true
    }
}

export default DealStore;