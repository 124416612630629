import request from 'superagent';

const AllowedMethods = {
    'get': 'get',
    'post': 'post',
    'put': 'put',
    'del': 'del',
    'download': 'get',
    'upload': 'post'
};

function resToPath(parts) {
    return parts ? '/' + (Array.isArray(parts) ? parts.map(res => encodeURIComponent(res)).join('/') : encodeURIComponent(parts)) : '';
}

class RestClient {
    constructor(clientId, endpoint, localAuthKey, afterLogin) {
        this.clientId = clientId;
        this.endpoint = endpoint;
        this.localAuthKey = localAuthKey;
        this.afterLogin = afterLogin;

        try {
            this.authToken = JSON.parse(window.localStorage.getItem(this.localAuthKey));
            this.afterLogin(this.authToken.user);
        } catch (err) {
            delete this.authToken;
        }
    }

    async login(username, password, thirdPartyInfo, invitationInfo) {
        this.logout();  
        const data = {username, password}
        if (thirdPartyInfo) {
            data.thirdPartyInfo = thirdPartyInfo
        }

        if (invitationInfo) {
            data.invitationInfo = invitationInfo
        }
        //console.log('login data', data)

        let res = await request.post(this.endpoint + '/' + 'login').send(data);

        let ret = res.body;

        if (ret.token) {
            this.authToken = ret;
            window.localStorage.setItem(this.localAuthKey, JSON.stringify(this.authToken));
            this.afterLogin(this.authToken.user);
        }
    }

    async register(formData){
        let res = await request.post(this.endpoint + '/' + 'register', formData);
        let registeredInfo;

        let ret = res.body

        console.log(ret)

        if ( ret.response && ret.response.id && ret.response.mobile) {
            registeredInfo = ret.response;
        } else {
            throw new Error('Register failed.');
        }
        return registeredInfo;
    }
    

    async activate(registeredData, code) {
        let res = await request.post([this.endpoint + '/' + 'activate' + '/' + registeredData.id ], {mobile: registeredData.mobile, code});
        let accountId;
        let ret = res.body
        if (ret.response && ret.response.id) {
            accountId = ret.response.id
        } else {
            throw new Error('Authentication failed.');
        }
        return accountId;
    }

    logout() {
        delete this.authToken;
        window.localStorage.removeItem(this.localAuthKey);
    }

    async do(method, path, query, body, onProgress, options) {
        method = method.toLowerCase();
        let httpMethod = AllowedMethods[method];
        if (!httpMethod) {
            throw new Error('Invalid method: ' + method);
        }

        let req = request[httpMethod]((options && options.endpoint ? options.endpoint : this.endpoint) + path)
            .set('X-GX-APP-ID', this.clientId);

        if (this.authToken) {
            req = req.set('Authorization', `Bearer ${this.authToken.token}`);
        }             

        if (query) {
            req.query(query);
        }

        if (method === 'download') {
            req.send(body);
        } else if (method === 'upload') {
            if (options && options.formData) {
                for (let k in options.formData) {
                    let v = options.formData[k];
                    req.field(k, v);                    
                }
            }
            
            req.attach("file", body);
        } else {
            req.send(body);
        }

        if (onProgress) {
            req.on('progress', onProgress);
        }

        try {
            let res = await req;

            if ((!res.body || res.body === '') && res.text !== '') {
                return res.text;
            }

            return res.body;
        } catch (error) {
            if (error.response && error.response.body) {
                if (error.response.statusCode === 401 && this.onUnauthorized) {                    
                    this.onUnauthorized();
                    return {};
                } else if (error.response.statusCode === 500) {
                    console.error(error);
                    throw new Error('System undergoing maintenance. Please try again later or contact TEAMLINK via helpdesk.');                    
                } else {
                    throw error.response.body.error;
                }
            } else {
                throw error;
            }
        }
    }

    async get(resource, query, options) {
        return this.do('get',
            resToPath(resource),
            query, null, null, options);
    }

    async post(resource, data, query, options) {
        return this.do('post',
            resToPath(resource),
            query, data, null, options);
    }

    async put(resource, data, query, options) {
        return this.do('put',
            resToPath(resource),
            query, data, null, options);
    }

    async del(resource, query, options) {
        return this.do('del',
            resToPath(resource),
            query, null, null, options);
    }

    async upload(resource, file, query, onProgress, options) {
        return this.do('upload',
            resToPath(resource),
            query, file, onProgress, options);
    }

    async loginBy(thirdPartyInfo) {
        try {
            this.logout();  
            console.log('>>>>>third party login info, end point', this.endpoint)

            let res = await request.post(this.endpoint + '/' + 'logInByThirdParty').send({thirdPartyInfo});

            let ret = res.body;

            console.log('>>>>>third party login response', ret)

            if (ret.token) {
                this.authToken = ret;
                window.localStorage.setItem(this.localAuthKey, JSON.stringify(this.authToken));
                this.afterLogin(this.authToken.user);
                return ''
            }

            return ret.response?ret.response.status:null
        } catch (err) {
            console.log(JSON.stringify(err, null, 2))
            //return 'waitingForBind'
        }
    }

    async registerAndBindThirdParty(data) {
        let res = await request.post(this.endpoint + '/' + 'registerAndBindThirdParty', data);
        let registeredInfo;

        let ret = res.body

        console.log(ret)

        if ( ret.response && ret.response.id && ret.response.mobile) {
            registeredInfo = ret.response;
        } else {
            throw new Error('Register failed.');
        }
        return registeredInfo;
    }
}

export default RestClient;
