import { format, parse } from 'date-fns';
import { isNullOrUndefined } from './typeCheck';
import { DateTime } from 'luxon';

const todayString = format(new Date(), 'dd/MM/yyyy');
// const thisYear = format(new Date(), 'yyyy');

const timeSimpleParse = (time) => {
    if (isNullOrUndefined(time)) {
        return "";
    }
    const date = new Date(time);
    const dateString = format(date, 'dd/MM/yyyy');
    if (dateString === todayString) {
        return format(date, 'hh:mm a');
    }

    return format(date, 'dd/MM/yyyy');
};

const timeParse = time => {
    if (isNullOrUndefined(time)) {
        return "";
    }
    const date = new Date(time);
    return format(date, 'hh:mm a');
}

const dateParse = (time) => {
    if (isNullOrUndefined(time)) {
        return "";
    }
    const date = new Date(time);
    return format(date, 'MMM dd, yyyy');
};

const dateStringParse = (dateString, formatString) => {
    return parse(dateString, formatString, new Date());
}

const formatDate = (time, formatString) => {
    const date = new Date(time)
    return format(date, formatString);
};

const toDate = date => DateTime.fromISO(date).setLocale('en-au').toLocaleString(DateTime.DATETIME_SHORT);

const toDateShort = date => DateTime.fromISO(date).setLocale('en-au').toLocaleString(DateTime.DATE_SHORT);

const timestampParse = (timestamp, zone = 'Australia/Sydney') => DateTime.fromMillis(timestamp).setLocale('en-au').setZone(zone).toLocaleString(DateTime.DATETIME_SHORT);

// TODO: change to use today rather now
const dueDateCheck = (date, days) => {
    const now = new Date();
    // now.setHours(0, 0, 0, 0);
    const last = new Date(now.getTime() + (days * 24 * 60 * 60 * 1000));
    const expiry = new Date(date);
    return last.getTime() >= expiry.getTime();
}

const dateToTime = date => {
    const dateTime = new Date(date);
    return dateTime.getTime();
}

const toRelative = (date, local = 'en') => {
    const now = DateTime.local()
    const target = DateTime.fromISO(date)
    if (now.diff(target, ['days']).toObject().days > 7) {
        return toDate(date)
    } else {
        return target.toRelative({ locale: local })
    }
}

const daysToNow = (date)=>{
    const now = DateTime.local()
    const target = DateTime.fromISO(date)
    return now.diff(target, ['days']).toObject().days
}

const UKdateParse = (time) => {
    if (isNullOrUndefined(time)) {
        return null
    }
    const date = new Date(time);
    return format(date, 'dd/MM/yyyy');
    // const date = DateTime.fromISO(time,{setZone : true}).toFormat('dd/MM/yyyy')
    // // console.log(date)
    // return date
}

const hhmmToDate = hhmm => {
    const times = hhmm.split(':');
    const date = new Date();
    date.setHours(times[0]);
    date.setMinutes(times[1]);
    return date;
}

const dateToHHmm = date => {
    return format(date, 'HH:mm');
}

const duration = (fromDate, toDate) => {
    const fromDateTime = new Date(fromDate);
    const toDateTime = new Date(toDate);
    const duration = (toDateTime.getYear() * 12 + toDateTime.getMonth()) - (fromDateTime.getYear() * 12 + fromDateTime.getMonth());
    const years = parseInt(duration / 12);
    const months = duration % 12;
    return `${years} year${years > 1 ? 's' : ''} ${months} month${months > 1 ? 's' : ''}`;
}

export { timeSimpleParse, timeParse, timestampParse, dateParse, dateStringParse, UKdateParse, toDate, toDateShort, formatDate, toRelative, dueDateCheck, dateToTime, hhmmToDate, dateToHHmm, duration,daysToNow };
