import {observable, action, computed, toJS} from 'mobx';
import _ from 'lodash'
import 'mobx-react-lite/batchingForReactDom'
import {clone} from '@src/utils/typeCheck'
import settings from '@src/config/app';
import { Polygon } from '../utils/map';

class PropertyStore {
    constructor(appStore,generalPropertyStore,mapStore,filterStore,uiStore) {
        this.appStore = appStore
        this.generalPropertyStore = generalPropertyStore
        this.mapStore = mapStore
        this.filterStore = filterStore
        this.uiStore = uiStore
    }


    listingCategory= {
        property:200,
        project:100
    }

    listingTypeMap = {
        selling:'sale',
        leasing:'lease'
    }


    @observable propertyList = []
    @action setPropertyList = value=>this.propertyList = value

    @observable allPropertyList = []
    @action setAllPropertyList = value=>this.allPropertyList = value

    @observable nearbyPropertyList = []
    @action setNearbyPropertyList = value=>this.nearbyPropertyList = value

    @observable collectionList = []
    @action setCollectionList = value=>this.collectionList = value

    @observable savedSearchList = []
    @action setSavedSearchList = value=>this.savedSearchList = value

    @observable recommendationList = []
    @action setRecommendationList = value=>this.recommendationList = value

    @observable mapMarkers = []
    @action setMapMarkers = value=>this.mapMarkers = value

    getMapProperties_ = _.debounce(async (centerCoordinate=this.mapStore.centerCoordinate,radius=this.mapStore.searchRadius)=>{
        this.uiStore.setIsBackgroundDataLoading(true)
        const filter = {
            ...this.filterStore.filterObject,
            mapCentre:centerCoordinate,
            mapWindow:radius,
            cluster: true
        }
        let res = []
        try {
            res = await this.getProperties_(filter)
            this.setMapMarkers(await this.mapStore.getMarkersFromProperties(res,filter))
            this.setPropertyList(this.generalPropertyStore.processListData(res.items).map(item=>this.additionalProcess(item)))
            // console.log('property list',this.propertyList,this.mapMarkers)
            if(this.uiStore.isExploreView){
                this.uiStore.setMapLoadingDataNum(res.items.length)
            }
        } catch (error) {
            this.uiStore.setMapLoadingDataNum(0)
            console.log('map fetching error:',error)
        } finally{
            this.uiStore.setIsBackgroundDataLoading(false)
        }
        return res
    },1000)

    getMapPolygonProperties_ = async (polygon)=>{
        this.uiStore.setIsBackgroundDataLoading(true)
        const filter = {
            ...this.filterStore.filterObject,
            mapPolygon:polygon,
            cluster: true
        };
        let res = []
        try { 
            res = await this.getProperties_(filter)
            this.setMapMarkers(await this.mapStore.getMarkersFromProperties(res,filter))
            this.setPropertyList(this.generalPropertyStore.processListData(res.items).map(item=>this.additionalProcess(item)))
            if(this.uiStore.isExploreView){
                this.uiStore.setMapLoadingDataNum(res.items.length)
            }
        } catch (error) {
            console.log('map fetching error',error)
        }finally{
            this.uiStore.setIsBackgroundDataLoading(false)
        }
        return res
    }

    /**
     * Use given polygons to filter out the properties from given markers.
     * This method is similar to getMapPolygonProperties_() but without calling
     * the backend API.
     * 
     * @param {Polygon} polygons - Multiple Polygon Object.
     * @returns {Boolean | Array} - Return an Array of filtered properties.
     */
     getMapPolygonLocalProperties_ = (polygons) => {
        this.uiStore.setIsBackgroundDataLoading(true)
        let res = [];
        try { 
            const polygonsArray = polygons.map(polygon => (new Polygon(polygon)));
            // console.log('this.mapMarkers', toJS(this.mapMarkers));
            // console.log('this.propertyList', toJS(this.propertyList));
            let filterIds = [];
            res = this.mapMarkers.filter(marker => {
                let isInside = false;
                for (let i = 0; i < polygonsArray.length; i++) {
                    const coordinate = marker?.properties?.data?.coordinate;
                    const point = {
                        latitude: coordinate.latitude,
                        longitude: coordinate.longitude
                    };
                    // Check if polygon if marker point is inside the polygon
                    if (polygonsArray[i].contains(point)) {
                        isInside = true;
                        filterIds.push(marker?.properties?.data?.rawData[0].id);
                        break;
                    }
                }
                return isInside;
            });

            this.setMapMarkers(res);
            // console.log('filterIds', filterIds);
            this.setPropertyList(this.propertyList.filter((item) => filterIds.indexOf(item.rawData.id) !== -1));
            if(this.uiStore.isExploreView){
                this.uiStore.setMapLoadingDataNum(res.length)
            }
        } catch (error) {
            console.log('map fetching error',error);
        } finally {
            this.uiStore.setIsBackgroundDataLoading(false)
        }
        return res;
    }


    getProperties_ = async (filter)=>{
        const res = await this.appStore.clientApi.getProperties_(filter)
        console.log('client getProperties_',res,filter)
        return res
    }


    @observable landingPageLoading = false
    @action setLandingPageLoading = value=>this.landingPageLoading = value



    getUniquePostcodeList = (rawData) =>{

        let result = []

        rawData && rawData.map(data=>{

            if(_.get(data,'postcode')){
                
                result = [...result, ...data["postcode"]]

            }

        })
        return _.uniq(result)
    }

    getLandPageProperties_ = async ()=>{

        this.setLandingPageLoading(true)

        const res = await this.appStore.clientApi.getNewProject_()

        const processedList = this.generalPropertyStore.processListData(res.items).map(item=>this.additionalProcess(item))
        
        const nearbyRes = await this.appStore.clientApi.getNearbyProperty_(this.appStore.userLocation || {
            "latitude": -33.8688,
            "longitude": 151.2093
        })
        const nearbyList = this.generalPropertyStore.processListData(nearbyRes.items).map(item=>this.additionalProcess(item))
        
        this.setAllPropertyList(processedList)
        this.setNearbyPropertyList(nearbyList)
        
        
        if(this.appStore.loggedInUser){


            const savedSearch = await this.appStore.clientApi.getSavedSearches_()
            const collectionRes = await this.appStore.clientApi.getCollection_()
            const collectionList = this.generalPropertyStore.processListData(collectionRes.items).map(item=>this.additionalProcess(item))
            // const collectionList = _.filter(processedAllList,function(o){return o.rawData.$isSaved})
            this.setCollectionList( _.isNull(collectionList) ? [] : collectionList)
            this.setSavedSearchList(_.isNull(savedSearch) ? [] : savedSearch)

            // recommendation list
            const postcodeArray = this.getUniquePostcodeList(savedSearch)
            const recommendationRes = await this.appStore.clientApi.getPropertyByPostcode_(postcodeArray)
            const recommendationList = this.generalPropertyStore.processListData(recommendationRes.items).map(item=>this.additionalProcess(item))
            this.setRecommendationList(recommendationList)

            console.log(processedList, nearbyList, collectionList, savedSearch,recommendationList )
        }

        console.log(processedList, nearbyList)

        this.setLandingPageLoading(false)
        
    }

    additionalProcess = (processedData)=>{
        let cloned = clone(processedData)
        const rawData = cloned.rawData
        cloned.processedData.isSaved = Boolean(rawData['$isSaved'])
        return cloned
    }

    favorProperty_ = async (listingId)=>{
        const res = await this.appStore.clientApi.saveAListingToCollection_(listingId,['favorite'])
        console.log('favor',res)
        
        return res
    } 

    unfavorProperty_ = async (listingId)=>{
        const res = await this.appStore.clientApi.delAListingFromCollection_(listingId)
        console.log('unfavor',res)
        return res
    }

    toggleDetailFavor = (newStatus)=>{
        let cloned = clone(this.currentDetails)
        cloned.processedData.isSaved = newStatus
        this.setCurrentDetails(cloned)
    }

    toggleFavorByListingId = (newState,listingId)=>{
        let cp = clone(this.propertyList)
        let findProperty = _.find(cp,o=>o.processedData.listingId===listingId)
        if(findProperty!== undefined){
            findProperty.processedData.isSaved = newState
            this.setPropertyList(cp)
        }
    }


    toggleFavorByIndexByType = (newState,index, type)=>{

        console.log(type)

        let cp;

        switch(type){
            case 'newDevelopment' :
                cp = clone(this.allPropertyList)
                break;

            case 'nearby' :
                cp = clone(this.nearbyPropertyList)
                break;
            case 'collection':
                cp = clone(this.collectionList)
                break;
            case 'recommendation':
                cp = clone(this.recommendationList)
                break;
        }

        console.log(cp, index)

        cp[index].processedData.isSaved = newState

        switch(type){
            case 'newDevelopment' :
                this.setAllPropertyList(cp)
                break;

            case 'nearby' :
                this.setNearbyPropertyList(cp)
                break;

            case 'collection':
                this.setCollectionList(cp)
                break;

            case 'recommendation':
                this.setRecommendationList(cp)
                break;
        }
    }


    toggleFavorByIndex = (newState,index)=>{
        let cp = clone(this.propertyList)
        cp[index].processedData.isSaved = newState
        this.setPropertyList(cp)
    }

    toggleFavorOfCurrentDetails = (newState)=>{
        let cp = clone(this.currentDetails)
        cp.processedData.isSaved = newState
        this.setCurrentDetails(cp)
    }

    getPropertyDetails_ = async (id)=>{
        const res = await this.appStore.clientApi.getProperty_(id)
        return this.additionalProcess(this.generalPropertyStore.processPropertyDetailResults(res))
    }

    getProjectDetails_ = async (id)=>{
        const res = await this.appStore.clientApi.getProject_(id)
        return this.additionalProcess(this.generalPropertyStore.processProjectDetailResults(res))
    }

    initPropertyDetailDataFromId_ = async (id,isProject)=>{
        let newPropertyData = null
        if(isProject){
            newPropertyData = await this.getProjectDetails_(id)
        }else{
            newPropertyData = await this.getPropertyDetails_(id)
        }
        if(this.appStore.loggedInUser){
            let data = null
            const detailsRes = await this.appStore.clientApi.getCollectionDetail_(newPropertyData.rawData.listing)
            // let cloned = clone(this.currentDetails)
            newPropertyData.processedData.isSaved = Boolean(detailsRes)
            data = {
                ...newPropertyData,
                collectionDetails:detailsRes
            }
            this.setCurrentDetails(data)
            console.log('login update',data)
        }else{
            // let cloned = clone(this.currentDetails)
            newPropertyData.processedData.isSaved = false
            const data = {
                ...newPropertyData,
                collectionDetails:null
            }
            this.setCurrentDetails(data)
            console.log('logout update',data)
        }
        return newPropertyData
    }

    initPropertyDetailData_ = async (rawData)=>{
        let newPropertyData = null
        if(this.generalPropertyStore.isProject(rawData)){
            newPropertyData = await this.getProjectDetails_(rawData.id)
        }else{
            newPropertyData = await this.getPropertyDetails_(rawData.id)
        }
        if(this.appStore.loggedInUser){
            let data = null
            const detailsRes = await this.appStore.clientApi.getCollectionDetail_(rawData.listing)
            // let cloned = clone(this.currentDetails)
            newPropertyData.processedData.isSaved = Boolean(detailsRes)
            data = {
                ...newPropertyData,
                collectionDetails:detailsRes
            }
            this.setCurrentDetails(data)
            console.log('login update',data)
        }else{
            // let cloned = clone(this.currentDetails)
            newPropertyData.processedData.isSaved = false
            const data = {
                ...newPropertyData,
                collectionDetails:null
            }
            this.setCurrentDetails(data)
            console.log('logout update',data)
        }
        return newPropertyData
    }


    @observable currentDetails = null
    @action setCurrentDetails = value=>this.currentDetails = value
    
    
    getBrochureUrl = (listingId)=>{
        return  `${settings.client.apiEndpoint}/listing/${listingId}/brochure`
    }

}

export default PropertyStore;
