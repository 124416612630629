import {observable, action, computed} from 'mobx';
import _ from 'lodash'
import settings from '@src/config/app';
import ClientApi from '@src/api/ClientApi';
import RestClient from 'gx-web-ui/RestClient';


class AppStore {
    constructor() {
        this.settings = settings;        

        this.client = new RestClient(
            settings.clientId,
            settings.client.apiEndpoint,
            settings.localStoreKey + '.token',
            this.onLogin
        );

        this.clientApi = new ClientApi(this.client, settings.fndEndpoint);

        
        if(typeof window !== 'undefined'){

            let locale =  window.localStorage.getItem(settings.localStoreKey + ".locale");

            locale && this.setLocale(locale);
        }
    }

    onLogin = (user) => {
        user && this.setLoggedInUser(user);
        this.setLoading(false);
    }

    logout = () => {
        this.setLoggedInUser(false);
        return this.clientApi.restClient.logout();
    }

    
    
    @observable loading = true;
    @observable loggedInUser = false;
    @observable userRole = 'client';
    @observable registeredData = {};

    @observable preference = {
        view: 'client',
        locale: 'en-US',
        disturb : false,
        city : 'Sydney',
        state : 'NSW',
        country : 'Australia'
    };
    
    @observable userLocation = null
    @action setUserLocation = (value) =>{
        this.userLocation = value
    }

    @observable searchParams = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : null;

    @action setLocation = (location) => {
        this.searchParams = new URLSearchParams(location.search);
    };

    
    @action setLoading = loading => this.loading = loading;
    @action setRoleView = role => this.userRole = role;
    @action setRegisteredData = form => this.registeredData = form;

    @action setLoggedInUser = user => {
        this.loggedInUser = user;
        let savedPref;
        if(typeof window !== 'undefined'){
            savedPref = window.localStorage.getItem(this.settings.localStoreKey+':'+user.id)
            // console.log( JSON.parse(savedPref))
        }
        if(user && savedPref){
            this.setPreference(JSON.parse(savedPref))
        }
    };



    login = async (username, password, thirdPartyInfo, invitationInfo) => {
        await this.clientApi.restClient.login(username, password, thirdPartyInfo, invitationInfo);
    }
        

    register = async(formData) => {
        let dataForm = await this.clientApi.restClient.register(formData);
        console.log(dataForm);
        if (dataForm) {
            this.setRegisteredData(dataForm);
        }
        return dataForm;
    }

    // login = (username, password) => 
    //     this.clientApi.restClient.login(this.settings.client.loginPath, username, password);

    activate = async (code) => {
        //console.log(this.registeredData.mobile, this.registeredData.id , code, '#####')
        return await this.clientApi.restClient.activate({mobile: this.registeredData.mobile, id: this.registeredData.id}, code);
    }
    
    @action setPreference = (pref, init=false) => {
        this.preference = pref;
        console.log(pref)
        if(typeof window !== 'undefined'){
            init ||  window.localStorage.setItem(this.settings.localStoreKey+':'+(this.loggedInUser ? this.loggedInUser.id : 'guest'), JSON.stringify(pref));
            // let savedPref = window.localStorage.getItem(this.settings.localStoreKey+':'+this.loggedInUser.id)
            // console.log(JSON.parse(savedPref))
        }

    };

    @observable loggedInPerson = null

    @action setLoggedInPerson = value => this.loggedInPerson = value

    getLoggedInPerson_ = async() =>{

        const result = await this.clientApi.getUserProfile_()

        this.setLoggedInPerson(result)

        return result

    }

    confirmUpdateUserProfile_ = async(data) =>{
        const result = await this.clientApi.updateUserProfile_(data)

        console.log(result)
    }

    forgetPasswordVerify_ = async(mobile) =>{
        const result = await this.clientApi.forgetPasswordWithMobile_(mobile)
        return result
    }

    loginByThirdParty = async (thirdPartyInfo) => {
        //console.log('>>>> login by third party', this.clientApi.restClient.loginBy, 'login', this.clientApi.restClient.login)
        return await this.clientApi.restClient.loginBy(thirdPartyInfo)
    }

    registerAndBindThirdParty = async (info) => {
        return await this.clientApi.restClient.registerAndBindThirdParty(info)
    }

    registerWithInvitation = async(formData) => {
        let dataForm = await this.clientApi.restClient.registerWithInvitation(formData);
        console.log(dataForm);
        if (dataForm) {
            this.setRegisteredData(dataForm);
        }
        return dataForm;
    }

    activateWithInvitation = async (code) => {
        const data = {id: this.registeredData.id, mobile: this.registeredData.mobile}
        console.log('register data:', JSON.stringify(this.registeredData , null, 2) , code, data)
        return await this.clientApi.restClient.activate(data, code);
    }

    updateAvatar_ = async (avatar)=>{
        await this.client.post("user-profile", {avatar});
        const tokenInfo = JSON.parse(window.localStorage.getItem(settings.localStoreKey + ".token"));
        const info = Object.assign({},this.loggedInUser, {avatar});
        this.setLoggedInUser(info);
        window.localStorage.setItem(settings.localStoreKey + ".token", JSON.stringify(Object.assign({}, tokenInfo, {user: info})));
    }

    @observable heartBeatFlag = false;

    @action refreshHeartBeat = () => {
        this.heartBeatFlag = !this.heartBeatFlag;
    }
}

export default AppStore;
