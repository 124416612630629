import validator from 'validator';

const isNull = (value) => value === null;
const isNullOrUndefined = (value) => typeof value === 'undefined' || value === null;
const clone = object => JSON.parse(JSON.stringify(object));

const includeChinese = (string) => {
    return escape(string).indexOf("%u") >= 0
}

const isValidHttpUrl = (string) => {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

const isJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const isHtml = (str) => {
    return /<\/?[a-z][\s\S]*>/i.test(str);
}

const isValidAcn = acn => {
    if (isNullOrUndefined(acn)) { return false };
    const trimAcn = acn.replace(/\s+/g, '');
    if (isNaN(trimAcn) || trimAcn.toString().length !== 9) { return false; }

    const digits = trimAcn.toString().split('').map(Number);
    const weightingDigits = digits.slice(0, digits.length - 1);
    const sum = weightingDigits.reduce((accumulator, currentValue, currentIndex) => currentValue * (8 - currentIndex) + accumulator, 0);
    const remainder = sum % 10;
    const checkDigit = digits[digits.length - 1];
    const calDigit = (10 - remainder) % 10;
    return calDigit === checkDigit;
}

const AbnWeights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];

const isValidAbn = abn => {
    if (isNullOrUndefined(abn)) { return false };
    const trimAbn = abn.replace(/\s+/g, '');
    if (isNaN(trimAbn) || trimAbn.toString().length !== 11) { return false; }

    const digits = trimAbn.toString().split('').map(Number);
    digits[0] = digits[0] - 1;

    const sum = digits.reduce((accumulator, currentValue, currentIndex) => currentValue * AbnWeights[currentIndex] + accumulator, 0);
    const remainder = sum % 89;
    return remainder === 0;
}

// only support Australia
const isValidMobilePhone = mobile => validator.isMobilePhone(mobile, ['en-AU']);
const isValidEmail = email => validator.isEmail(email);
const isValidUsername = value => {
    return isValidMobilePhone(value) || isValidEmail(value)
}

const isValidPassword = password => {
    const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,64}$/;
    const notPattern = /[. ]+/;
    return password.match(pattern) !== null && password.match(notPattern) === null && !['Passw0rd', 'Password123'].includes(password);;
}

const codeEmail = email => email.replace(/(\S).+(\S@\S).+(\.\S+$)/, "$1*******$2*****$3");
const codeMobile = mobile => mobile.replace(/(\+\d{3})\d{7}/, "$1*******");

export {
    isNull, isNullOrUndefined, isJsonString,
    isHtml, isValidAcn, isValidAbn,
    isValidPassword, isValidEmail, isValidMobilePhone, isValidUsername,
    clone, codeEmail, codeMobile, includeChinese,
    isValidHttpUrl
};
