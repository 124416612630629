import { observable, action, computed, extendObservable } from 'mobx';

import remove from 'lodash/remove'

class UiStore {
    constructor(appStore){
        this.appStore = appStore
    }

    @observable notifications = []

    @action enqueueSnackbar=(note)=>{
        this.notifications.push({
            key: new Date().getTime() + Math.random(),
            ...note,
        });
    }

    addNotification=(note)=>{
        this.enqueueSnackbar(note)
    }

    @action removeSnackbar=(note)=>{
        remove(this.notifications,o=>o.key===note)
    }


    checkLogin = (func)=>(...args)=>{
        if(this.appStore.loggedInUser){
            func(...args)
        }else{
            this.setOpenLogin(true)
        }
    }

    @observable openLogin = false
    @action setOpenLogin = value=>this.openLogin = value

    @observable openTabletMenu = false
    @action setOpenTabletMenu = value=>this.openTabletMenu = value

    @observable isMeSettingOpen = false

    @action setIsMeSettingOpen = (value) => {

        this.isMeSettingOpen = value
        console.log(this.isMeSettingOpen)
    }

    @action switchMeSetting = () =>{
        this.isMeSettingOpen = this.isMeSettingOpen ? false : true
    }

    @observable isProfileDetailOpen = false
    @action setIsProfileDetailOpen = (value) =>{
        this.isProfileDetailOpen = value
    }
    
    @observable currentListHoverListingId = null
    @action setCurrentListHoverListingId = value=>this.currentListHoverListingId = value

    @observable inspectionPropertyListingId = null
    @action setInspectionPropertyListingId = value=> {this.inspectionPropertyListingId = value;}

    @observable inspectionClicked = false
    @action setInspectionClicked = value=>this.inspectionClicked = value

    @observable isBackgroundDataLoading = false
    @action setIsBackgroundDataLoading = value=>this.isBackgroundDataLoading = value

    @observable mapLoadingDataNum = 0
    @action setMapLoadingDataNum = value=>this.mapLoadingDataNum = value

    @observable explorePageCurrentTab = null
    @action setExplorePageCurrentTab = value=>{
        if(this.explorePageCurrentTab!=='messages'){
            this.setPreviousPageTab(this.explorePageCurrentTab)
        }
        this.explorePageCurrentTab = value
    }

    @observable previousPageTab = null
    @action setPreviousPageTab = value=>this.previousPageTab = value
    
    @computed get isExploreView(){
        return this.explorePageCurrentTab === 'explore'
    }

    @computed get isCollectionView(){
        return this.explorePageCurrentTab === 'collections'
    }

    @computed get isMessagesView(){
        return this.explorePageCurrentTab === 'messages'
    }


    @observable apiLoaded = false
    @action setApiLoaded = value=>this.apiLoaded = value
    
    @observable loadedGoogleMapInstance = null
    @action setLoadedGoogleMapInstance = value=>this.loadedGoogleMapInstance = value

    @observable isVerificationOpen = false
    @action setIsVerificationOpen = value=>this.isVerificationOpen = value

    @observable developerDetailPanelOpen = false
    @action setDeveloperDetailPanelOpen = value=>this.developerDetailPanelOpen = value

    @observable isShowRecommendation = false
    @action setIsShowRecommendation = value=>this.isShowRecommendation = value

    @observable isMessagePanelVisible = false
    @action setIsMessagePanelVisible = value=>this.isMessagePanelVisible = value

    messageReceiverId = 0
    setMessageReceiverId = value=>this.messageReceiverId = value

    sentPropertyListing = false
    setSentPropertyListing = value=>this.sentPropertyListing = value
}

export default UiStore;