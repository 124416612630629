import { observable, action, computed} from "mobx";
import _ from 'lodash'
import {format, parse, parseISO} from 'date-fns'
import { isNullOrUndefined } from "utils/typeCheck";
import numeral from "numeral";

class PipelineStore {
    constructor(appStore) {
        this.appStore = appStore;
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    @observable ossData = null
    @action setOssData = value=>this.ossData = value

    @observable fillOnline = true
    @action setFillOnline = value=>this.fillOnline = value

    @observable refundFormFilledOnline = false
    @action setRefundFormFilledOnline = value=>this.refundFormFilledOnline = value

    @observable registrationFormReady = false
    @action setRegistrationFormReady = value => this.registrationFormReady = value

    @observable subjectToFIRB = false
    @action setSubjectToFIRB = value=>this.subjectToFIRB = value

    @observable source = null
    @action setSource = value=>this.source = value

    @observable hasPurchaserSolicitor = false
    @action setHasPurchaserSolicitor = value=>this.hasPurchaserSolicitor = value

    @observable referredBy = ''
    @action setReferredBy = value=>this.referredBy = value

    @observable solicitorData = {}
    @action setSolicitorData = value=>this.solicitorData = value

    @observable fellOverWithRefund = false
    @action setFellOverWithRefund = value=>this.fellOverWithRefund = value

    @observable isCurrentPipelineFellOver = false
    @action setIsCurrentPipelineFellOver = value=> this.isCurrentPipelineFellOver = value

    @observable tempUploadedRegistrationForm = null
    @action setTempUploadedRegistrationForm = value=>this.tempUploadedRegistrationForm = value

    @observable tempUploadedDeposit = null
    @action setTempUploadedDeposit = value=>this.tempUploadedDeposit = value
    
    @observable depositPaymentMethod = null
    @action setDepositPaymentMethod = value=>this.depositPaymentMethod = value
    
    @observable depositDate = null
    @action setDepositDate = value=>this.depositDate = value
    

    @observable depositAmount = null
    @action setDepositAmount = value=> this.depositAmount = value

    @observable tempUploadedTenPercentDepositReceipt = null
    @action setTempUploadedTenPercentDepositReceipt = value=>this.tempUploadedTenPercentDepositReceipt = value

    @observable tempUploadedExecutedContractFrontPage = null
    @action setTempUploadedExecutedContractFrontPage = value=>this.tempUploadedExecutedContractFrontPage = value

    @observable tempUploadedExecutedContractSignedPage = null
    @action setTempUploadedExecutedContractSignedPage = value=>this.tempUploadedExecutedContractSignedPage = value

    @observable tempUploadedAuthorityToExchange = null
    @action setTempUploadedAuthorityToExchange = value=>this.tempUploadedAuthorityToExchange = value

    @observable refundReference = ''
    @action setRefundReference = value => this.refundReference = value

    @observable refundReason = ''
    @action setRefundReason = value => this.refundReason = value

    @observable refundBankName = ''
    @action setRefundBankName = value => this.refundBankName = value

    @observable refundAccountName = ''
    @action setRefundAccountName = value => this.refundAccountName = value

    @observable refundAccountBsb = ''
    @action setRefundAccountBsb = value => this.refundAccountBsb = value

    @observable refundAccountNumber = ''
    @action setRefundAccountNumber = value => this.refundAccountNumber = value

    @observable refundDepositAmount = ''
    @action setRefundDepositAmount = value => this.refundDepositAmount = value

    @observable developerInfo = null
    @action setDeveloperInfo = value => this.developerInfo=value

    @observable priceAndCommission = {}
    @action setPriceAndCommission = value => this.priceAndCommission = value

    @observable mainCategory = null
    @action setMainCategory = value => this.mainCategory = value
    
    @observable declineReason = null
    @action setDeclineReason = value => this.declineReason = value

    @observable agencyId = null
    @action setAgencyId = value => this.agencyId = value

    @observable documents = []
    @action setDocuments = value => this.documents = value

    processPriceAndCommission = (rawData, isAgencyAdmin) =>{
        if(isAgencyAdmin){
            const commissionOfAgents = rawData[":commissionOfAgents"]
            this.setPriceAndCommission({
                contractPrice : this.priceAndCommission.contractPrice,
                totalCommission : _.sumBy(commissionOfAgents, function(o) { return o.totalCommission; }),
                payAtExchange : _.sumBy(commissionOfAgents, function(o) { return o.firstPaymentAmount; }),
                payAtSettlement : _.sumBy(commissionOfAgents, function(o) { return o.secondPaymentAmount; })
            })
        }else{
            this.setPriceAndCommission({
                contractPrice : this.priceAndCommission.contractPrice,
                totalCommission : rawData.totalCommission,
                payAtExchange : rawData.firstPaymentAmount,
                payAtSettlement : rawData.secondPaymentAmount
            })
        }
    }

    getListOfDevelopers_ = async() =>{
        const resList = await this.appStore.client.get(['developer'])
    }

    
    getDeveloperDetail_ = async(id=this.developerId) =>{
        if(id){
            const {response} = await this.appStore.client.get(['developer',id])
            this.setDeveloperInfo({
                developerId : response.id,
                developer : response.name,
                phone : response[":company"]?.phone,
                fax: response[":company"]?.fax,
                email: response[":company"]?.email,
                ACN: response[":company"]?.acn,
                address : response[":company"]?.businessAddress 
            })
        }else{
            this.setDeveloperInfo({
                developerId : '',
                developer : '',
                phone : '',
                fax: '',
                email: '',
                ACN: '',
                address : '' 
            })
        }
        console.log(this.developerInfo)
    }

    updateDeveloperDetail_ = async(id=this.developerId, info) =>{
        /*if(id){
            await this.appStore.client.put(['developer',developerId],info)
        }*/
    }

    processDeveloperInformation_ = async(rawData) =>{
        this.setMainCategory(rawData?.[':listing']?.["mainCategory"])
        this.setDeveloperId(rawData.developer || this.processDeveloperData(rawData))
        await this.getDeveloperDetail_()
    }


    getCurrentStageByPipelineStatus = status=>{
        switch (status) {
            case 'draft':
                return 0
            case 'reserved':
                return 1
            case 'contractIssued':
                return 2
            case 'exchanged':
                return 3
            case 'settled':
                return 4
            case 'fellOver':
                return 5
        }
    }

    @observable currentStage = 0
    @action setCurrentStage = value=>this.currentStage = value

    @computed get stageFinished(){
        return {
            underReservation:this.currentStage>0,
            underContract:this.currentStage>1,
            pendingExchange:this.currentStage>2,
            pendingSettlement:this.currentStage>3
        }
    }

    @observable currentStageSubmitStatus = 'draft'
    @action setCurrentStageSubmitStatus = value=>this.currentStageSubmitStatus = value


    processSolicitorData = (rawData)=>{
        const solicitorData = rawData[':solicitor']
        return {
            companyName:solicitorData?.companyName||'',
            firstName:solicitorData?.firstName||'',
            lastName:solicitorData?.lastName||'',
            phone:solicitorData?.phone||'',
            email:solicitorData?.email||'',
            fax:solicitorData?.fax||'',
            line1:solicitorData?.[":address"]?.line1||'',
            line2:solicitorData?.[":address"]?.line2||'',
            suburb:solicitorData?.[":address"]?.city||'',
            state:solicitorData?.[":address"]?.state||'',
            postcode:solicitorData?.[":address"]?.postcode||''
        }
    }
    
    setSolicitorDataByName=(name)=>(value)=>{
        this.setSolicitorData({
            ...this.solicitorData,
            [name]:value
        })
    }

    setDeveloperDataByName=(name)=>(value)=>{
        this.setDeveloperInfo({
            ...this.developerInfo,
            [name]:value
        })
    }
    


    @computed get hasSignature(){
        return this.purchasers.some(p=>p.exchangeWitnessName!==null)
    }



    @observable depositPaid = []
    @action setDepositPaid = value=>this.depositPaid = value

    @observable purchasers = []
    @action setPurchasers = value=>this.purchasers = value

    @observable developerId = {}
    @action setDeveloperId = value=>this.developerId = value



    processPurchaserData = (pipelineData)=>{
        if(pipelineData[':purchasers']){
            return pipelineData[':purchasers'].map(purchaser=>{
                const photoId = _.find(purchaser?.[':resourceGroup']?.[':resources']||[],{mediaTag:"PHOTO"})
                return {
                    id:purchaser.id,
                    firstName:purchaser.firstName,
                    lastName:purchaser.lastName,
                    dob:purchaser.dob,
                    residency:purchaser.residency,
                    phone:purchaser.phone,
                    email:purchaser.email,
                    line1:purchaser[':address'].line1,
                    line2:purchaser[':address'].line2,
                    suburb:purchaser[':address'].city,
                    state:purchaser[':address'].state,
                    postcode:purchaser[':address'].postcode,
                    depositHolder:Boolean(purchaser.depositHolder),
                    firstHomeBuyer:Boolean(purchaser.firstHomeBuyer),
                    purchaseReason:purchaser.purchaseReason,
                    companyPurchaser:Boolean(purchaser.purchaserCompany),
                    companyName:purchaser?.[':purchaserCompany']?.name||'',
                    companyDirector:purchaser?.[':purchaserCompany']?.directorName||'',
                    acnAbn:purchaser?.[':purchaserCompany']?.abnOrAcn||'',
                    photoIdUrl:photoId?.url||null,
                    photoIdFileName:photoId?.fileName||null,
                    exchangeSignature: purchaser.exchangeSignature ? "data:image/png;base64," + purchaser.exchangeSignature : purchaser.exchangeSignature,
                    exchangeWitnessName: purchaser.exchangeWitnessName,
                    exchangeWitnessSignature: purchaser.exchangeWitnessSignature ? "data:image/png;base64," +purchaser.exchangeWitnessSignature : purchaser.exchangeWitnessSignature,
                    refundSignature: purchaser.refundSignature ? "data:image/png;base64," + purchaser.refundSignature : purchaser.refundSignature,
                    refundWitnessName:purchaser.refundWitnessName,
                    refundWitnessSignature:purchaser.refundWitnessSignature ? "data:image/png;base64," +purchaser.refundWitnessSignature : purchaser.refundWitnessSignature,
                    rawData:purchaser,
                    modifyStatus:null
                }
            })
        }else{
            return []
        }
    }

    processDeveloperData = (pipelineData) =>{
        return pipelineData[':listing']?.[':property']?.[':project']?.developer;
    }

    processDeveloperSolicitorData = (pipelineData) =>{
        try{

        }
        catch{
            return null
        }
    }

    processVendorData = (pipelineData) =>{
        try{
            return pipelineData[':listing']?.['vendor']
        }
        catch{
            return null
        }
    }

    processVendorSolicitorData = (pipelineData) =>{
        try{
            return pipelineData[':listing']?.['vendorSolicitor']
        }
        catch{
            return null
        }
    }

    submitRegistration_ = async (dealId)=>{
        const result = await this.appStore.client.put(['sales-in-progress',dealId,'action','submitRegistration'])
        return result.response
    }



    onAddPurchaser = data=>{
        console.log(data)
        this.setPurchasers([
            ...this.purchasers,
            _.assign(data,{
                modifyStatus:'add',
                photoIdUrl:data?.photoIdObj?.photoIdUrl||null,
                photoIdFileName:data?.photoIdObj?.name||null
            })
        ])
    }
    
    onChangePurchaser = (id)=>action(data=>{
        console.log(id,data)
        const foundItem = _.find(this.purchasers,{id:id})
        _.assign(foundItem,data,{
            modifyStatus:foundItem.modifyStatus === 'add'?'add':'update',
            photoIdUrl:data.photoIdObj?data.photoIdObj.photoIdUrl:foundItem.photoIdUrl,
            photoIdFileName:data?.photoIdObj?.name||null
        })
        this.setPurchasers(this.purchasers)
    })
    

    onSavePurchasers_ = async ()=>{
        const assembleData = (purchaser)=>{
            return {
                phone:purchaser.phone,
                email:purchaser.email,
                address:{
                    line1:purchaser.line1,
                    line2:purchaser.line2,
                    city:purchaser.suburb,
                    state:purchaser.state,
                    postcode:purchaser.postcode
                },
                depositHolder:purchaser.depositHolder,
                firstHomeBuyer:purchaser.firstHomeBuyer,
                purchaseReason:purchaser.purchaseReason,
                resources:[
                    {
                        type:purchaser.photoIdUrl.endsWith('.pdf')?'DOC':"IMAGE",
                        mediaTag:"PHOTO",
                        url:purchaser.photoIdUrl,
                        fileName:purchaser.photoIdObj?purchaser.photoIdObj.name:null
                    }
                ],
                ...purchaser.companyPurchaser?{
                    company:{
                        name:purchaser.companyName,
                        directorName:purchaser.companyDirector,
                        abnOrAcn:purchaser.acnAbn
                    }
                }:{
                    firstName:purchaser.firstName,
                    lastName:purchaser.lastName,
                    dob:format(parseISO(purchaser.dob),'yyyy-MM-dd'),
                    residency:_.toLower(purchaser.residency),
                },
                exchangeWitnessName:purchaser.exchangeWitnessName,
                ...purchaser.updateSignatures?.exchangeSignature?{
                    exchangeSignature: purchaser.updateSignatures.exchangeSignature.slice(purchaser.updateSignatures.exchangeSignature.indexOf(',')+1),
                }:{},
                ...purchaser.updateSignatures?.exchangeWitnessSignature?{
                    exchangeWitnessSignature: purchaser.updateSignatures.exchangeWitnessSignature.slice(purchaser.updateSignatures.exchangeWitnessSignature.indexOf(',')+1),
                }:{},
            }
        }
        const requests = this.purchasers.filter(purchaser=>purchaser.modifyStatus!==null).map((purchaser)=>{
            console.log(purchaser)
            if(purchaser.modifyStatus === 'add'){
                return this.appStore.client.post(['sales-in-progress',this.pipelineData.id,'purchaser'],assembleData(purchaser))
            }else if (purchaser.modifyStatus === 'update'){
                return this.appStore.client.put(['sales-in-progress',this.pipelineData.id,'purchaser',purchaser.id],assembleData(purchaser))
            }
        })

        const res = await Promise.all(requests)
        this.setPurchasers(this.purchasers.map(purchaser=>({
            ...purchaser,
            modifyStatus:null,
            updateSignatures:null
        })))
        return res
    }

    onSaveFellOverPurchasers_ = async ()=>{
        const assembleData = (purchaser)=>{
            return {
                refundWitnessName:purchaser.refundWitnessName,
                ...purchaser.updateFellOverSignatures?.refundSignature?{
                    refundSignature: purchaser.updateFellOverSignatures.refundSignature,
                }:{},
                ...purchaser.updateFellOverSignatures?.refundWitnessSignature?{
                    refundWitnessSignature: purchaser.updateFellOverSignatures.refundWitnessSignature,
                }:{},
            }
        }
        const requests = this.purchasers.filter(purchaser=>purchaser.modifyStatus!==null).map((purchaser)=>{
            console.log(purchaser)
            if(purchaser.modifyStatus === 'add'){
                return this.appStore.client.post(['sales-in-progress',this.pipelineData.id,'purchaser'],assembleData(purchaser))
            }else if (purchaser.modifyStatus === 'update'){
                return this.appStore.client.put(['sales-in-progress',this.pipelineData.id,'purchaser',purchaser.id],assembleData(purchaser))
            }
        })

        const res = await Promise.all(requests)
        this.setPurchasers(this.purchasers.map(purchaser=>({
            ...purchaser,
            modifyStatus:null,
            updateFellOverSignatures:null
        })))
        return res
    }

        
    startFellOver_= async (id=this.pipelineData.id)=>{
        const res = await this.appStore.client.put(['sales-in-progress',id,'action','fallOver'])
        return res.response
    }
    

    onSaveForm_= async ()=>{
        if(this.fillOnline){
            //solicitor
            if(this.pipelineData.solicitor && !this.hasPurchaserSolicitor){
                //delete Solicitor
                await this.deleteSolicitorSaleInProgress_(this.pipelineData.solicitor)
            }else if(this.hasPurchaserSolicitor){
                
                //add or update solicitor
                const solicitorData = {
                    companyName:this.solicitorData.companyName,
                    firstName:this.solicitorData.firstName,
                    lastName:this.solicitorData.firstName,
                    phone:this.solicitorData.phone,
                    email:this.solicitorData.email,
                    fax:this.solicitorData.fax,
                    address: {
                        line1:this.solicitorData.line1,
                        line2:this.solicitorData.line2,
                        city:this.solicitorData.suburb,
                        state:this.solicitorData.state,
                        postcode:this.solicitorData.postcode
                    }
                }
                await this.addOrUpdateSolicitorSaleInProgress_(solicitorData)
            }
            await this.updateSaleInProgressDetail_({
                subjectToFirb:this.subjectToFIRB,
                source:this.source,
                referredBy:this.referredBy,
            });
        }else{
            if(this.tempUploadedRegistrationForm){
                await this.updateSaleInProgressDetail_({
                    registrationForm: this.tempUploadedRegistrationForm,
                });
                this.setPipelineFiles({
                    ...this.pipelineFiles,
                    registrationForm:this.tempUploadedRegistrationForm
                })
                this.setTempUploadedRegistrationForm(null)
            }
        }
    }


    onSaveDeposit_= async ()=>{
        let updateInfo = {}
        if(this.tempUploadedDeposit){
            this.setPipelineFiles({
                ...this.pipelineFiles,
                depositReceipt:this.tempUploadedDeposit
            })

            updateInfo = {depositReceipt :  this.tempUploadedDeposit}
            this.setTempUploadedDeposit(null)
        }

        await this.updateSaleInProgressDetail_({
            ...updateInfo,
            depositPaymentMethod: this.depositPaymentMethod,
        });
    }


    onSavePendingExchangeFiles_ = async ()=>{
        let info = {}
        // if(this.tempUploadedTenPercentDepositReceipt){
        //     info = {
        //         ...info,
        //         exchangeDepositReceipt : this.tempUploadedTenPercentDepositReceipt
        //     }
        // }

        // if(this.tempUploadedExecutedContractFrontPage){
        //     info = {
        //         ...info,
        //         contractFrontPage: this.tempUploadedExecutedContractFrontPage
        //     }
        // }

        // if(this.tempUploadedExecutedContractSignedPage){
        //     info = {
        //         ...info,
        //         contractSignPage: this.tempUploadedExecutedContractSignedPage
        //     }
        // }

        // if(this.tempUploadedAuthorityToExchange){
        //     info = {
        //         ...info,
        //         contract66W: this.tempUploadedAuthorityToExchange
        //     }
        // }

        // if(!_.isEmpty(info)){
        //     await this.updateSaleInProgressDetail_(info);
        // }

         if(this.tempUploadedTenPercentDepositReceipt){
            info = {
                ...info,
                exchangeDepositReceipt : this.tempUploadedTenPercentDepositReceipt
            }
        }else{
            if(this.pipelineFilesCp['exchangeDepositReceipt'] !== this.pipelineFiles['exchangeDepositReceipt']){
                info = {
                    ...info,
                    exchangeDepositReceipt : null
                }
            }
        }

        if(this.tempUploadedExecutedContractFrontPage){
            info = {
                ...info,
                contractFrontPage: this.tempUploadedExecutedContractFrontPage
            }
        }else{
            if(this.pipelineFilesCp['frontPage'] !== this.pipelineFiles['frontPage']){
                info = {
                    ...info,
                    contractFrontPage : null
                }
            }
        }
        

        if(this.tempUploadedExecutedContractSignedPage){
            info = {
                ...info,
                contractSignPage: this.tempUploadedExecutedContractSignedPage
            }
        }else{
            if(this.pipelineFilesCp['signedPage'] !== this.pipelineFiles['signedPage']){
                info = {
                    ...info,
                    contractSignPage : null
                }
            }
        }

        if(this.tempUploadedAuthorityToExchange){
            info = {
                ...info,
                contract66W: this.tempUploadedAuthorityToExchange
            }
        }else{
            if(this.pipelineFilesCp['66W'] !== this.pipelineFiles['66W']){
                info = {
                    ...info,
                    contract66W : null
                }
            }
        }

        if(!_.isEmpty(info)){
            await this.updateSaleInProgressDetail_(info);
        }

    }
    

    getPipelineOssData_ = async (listingSaleId)=>{

        const res = await this.appStore.clientApi.getOssSignature_('listingSale', listingSaleId)

        console.log('set oss data')
        this.setOssData(res)

    }

    reloadPipelineData_ = async (dealId=this.pipelineData.id)=>{
        const {response} = await this.appStore.client.get(['sales-in-progress',dealId])
        await this.getPipelineOssData_(dealId)
        this.setPipelineData(response)
        this.initPipelineFromRawData(response)
    }

    getAgentCommissionInformation_ = async(saleId = this.pipelineData.id) =>{
        const result = await this.appStore.client.get(['sales-in-progress',saleId,'commission'])

        console.log(result)
        return result.response
    }




    @observable pipelineData = null
    @action setPipelineData = value => this.pipelineData = value

    initPipelineFromRawData = (rawData=this.pipelineData)=>{
        this.setPurchasers(this.processPurchaserData(rawData)||[])

        this.setFillOnline(!Boolean(rawData.registrationForm))
        this.setRefundFormFilledOnline(Boolean(rawData.registrationForm)?false:!Boolean(rawData.refundForm))
        this.setSubjectToFIRB(Boolean(rawData.subjectToFirb)||false)
        this.setCurrentStage(this.getCurrentStageByPipelineStatus(rawData.status))
        this.setRegistrationFormReady((rawData.registrationFormStatus !== null && rawData.registrationFormStatus !== "draft")||false)
        this.setCurrentStageSubmitStatus(rawData.submitStatus)
        this.setIsCurrentPipelineFellOver(Boolean(rawData.fellOver))
        this.setFellOverWithRefund(Boolean(rawData.requireRefund))
        this.setDepositDate(rawData.depositedDate)
        this.setDepositAmount(rawData.depositedAmount ? rawData.depositedAmount  : 5000)
        this.setSource(rawData.source||null)
        this.setHasPurchaserSolicitor(Boolean(rawData.solicitor))
        this.setSolicitorData(this.processSolicitorData(rawData))
        this.setReferredBy(rawData.referredBy || '')
        this.setDepositPaid(rawData.depositPaid||false)
        this.setPriceAndCommission({contractPrice : rawData.contractPrice || ''})
        this.setDepositPaymentMethod(rawData.depositPaymentMethod||'other')
        let files = {}
        Object.keys(this.uploadingConfig).map(key=>({fieldName:this.uploadingConfig[key].fieldName,key})).forEach(item=>{
            files[item.key] = rawData[item.fieldName]
        })

        // this.setDeclineReason(rawData.declinedReason)
        this.setPipelineFiles(files)
        this.setPipelineFilesCp(files)
        
        this.setTempUploadedRegistrationForm(null)
        this.setTempUploadedDeposit(null)

        this.setTempUploadedTenPercentDepositReceipt(null)
        this.setTempUploadedExecutedContractFrontPage(null)
        this.setTempUploadedExecutedContractSignedPage(null)
        this.setTempUploadedAuthorityToExchange(null)
        
    }

    uploadingConfig = {
        exchangeDepositReceipt:{
            fieldName:'exchangeDepositReceipt',
            pdfTitle:`Client's 10% deposit receipt`
        },
        frontPage:{
            fieldName:'contractFrontPage',
            pdfTitle:`Executed contract front page`
        },
        signedPage:{
            fieldName:'contractSignPage',
            pdfTitle:`Executed contract signed page`
        },
        '66W':{
            fieldName:'contract66W',
            pdfTitle:`Authority to exchange / 66W`
        },
        invoice:{
            fieldName:'exchangedCommissionInvoice',
            pdfTitle:`Commission invoice`
        },
        psiReport:{
            fieldName:'psiReport',
            pdfTitle:`PSI report`
        },
        orderOnAgent:{
            fieldName:'orderOnAgent',
            pdfTitle:`Order on agent`
        },
        settledCommissionInvoice:{
            fieldName:'settledCommissionInvoice',
            pdfTitle:`Commission invoice`
        },
        refundForm:{
            fieldName:'refundForm',
            pdfTitle:`Refund form`
        },
        registrationForm:{
            fieldName:'registrationForm',
            pdfTitle:`Registration form`
        },
        depositReceipt:{
            fieldName:'depositReceipt',
            pdfTitle:`Deposit receipt`
        },
        salesAdvice:{
            fieldName:'salesAdvice',
            pdfTitle:'Sales advice'
        }
    }


    @observable pipelineFiles = {
        exchangeDepositReceipt:null,
        frontPage:null,
        signedPage:null,
        '66W':null,
        invoice:null,
        psiReport:null,
        orderOnAgent:null,
        settledCommissionInvoice:null,
        refundForm:null,
        registrationForm:null,
        depositReceipt:null,
        salesAdvice:null
    }

    @action setPipelineFiles = value=>this.pipelineFiles = value

    @observable pipelineFilesCp = {
        exchangeDepositReceipt:null,
        frontPage:null,
        signedPage:null,
        '66W':null,
        invoice:null,
        psiReport:null,
        orderOnAgent:null,
        settledCommissionInvoice:null,
        refundForm:null,
        registrationForm:null,
        depositReceipt:null,
        salesAdvice:null
    }

    @action setPipelineFilesCp = value=>this.pipelineFilesCp = value

    
    clearPipelineStore = ()=>{
        this.setOssData(null)
        this.setPipelineData(null)
        this.setCurrentStage(0)
        this.setCurrentStageSubmitStatus('draft')
        this.setPurchasers([])
        this.setFillOnline(true)
        this.setRefundFormFilledOnline(true)
        this.setSubjectToFIRB(false)
        this.setRegistrationFormReady(false)
        this.setSource(null)
        this.setIsCurrentPipelineFellOver(false)
        this.setFellOverWithRefund(false)
        this.setHasPurchaserSolicitor(false)
        this.setSolicitorData({})
        this.setReferredBy('')
        this.setDepositPaid(false)
        this.setPriceAndCommission({})
        this.setDepositDate(null)
        this.setDepositAmount(5000)
        this.setDepositPaymentMethod('other')
        this.setPipelineFiles(_.mapValues(this.uploadingConfig,o=>null))
        this.setPipelineFilesCp(_.mapValues(this.uploadingConfig,o=>null))
        this.setTempUploadedRegistrationForm(null)
        this.setTempUploadedDeposit(null)
        this.setDeclineReason(null)
        this.setDeveloperId(null)
        this.setMainCategory(null)
        this.setTempUploadedTenPercentDepositReceipt(null)
        this.setTempUploadedExecutedContractFrontPage(null)
        this.setTempUploadedExecutedContractSignedPage(null)
        this.setTempUploadedAuthorityToExchange(null)
    }

    @observable resetUnderReservationDataCounter = 1
    @action triggerResetUnderReservationData = ()=> this.resetUnderReservationDataCounter += 1

    updateSaleInProgressDetail_ = async (info,id=this.pipelineData.id,)=>{
        const res = await this.appStore.client.put(['sales-in-progress',id],info)
        return res
    }

    deleteSolicitorSaleInProgress_ = async (solicitorId,saleId=this.pipelineData.id)=>{
        const result = await this.appStore.client.del(['sales-in-progress',saleId,'solicitor',solicitorId])
        return result.response
    }

    addOrUpdateSolicitorSaleInProgress_ = async (solicitor,saleId=this.pipelineData.id)=>{
        const result = await this.appStore.client.post(['sales-in-progress',saleId,'solicitor'],solicitor)
        return result.response
    }

    approvePipeline_ = async (saleId=this.pipelineData.id)=>{
        const result = await this.appStore.client.put(['sales-in-progress',saleId,'action','approve'])
        return result.response
    }

    declinePipeline_ = async (saleId=this.pipelineData.id)=>{
        const result = await this.appStore.client.put(['sales-in-progress',saleId,'action','decline'],{declinedReason: this.declineReason})
        return result.response
    }

    completeUnderContract_ = async (saleId=this.pipelineData.id)=>{
        const result = await this.appStore.client.put(['sales-in-progress',saleId,'action','markContractDone'])
        return result.response
    }

    initiateSettlement_ = async (saleId=this.pipelineData.id)=>{
        const result = await this.appStore.client.put(['sales-in-progress',saleId,'action','startSettle'])
        return result.response
    }

    generateSalesAdvice_ = async (saleId=this.pipelineData.id)=>{
        const result = await this.appStore.client.put(['sales-in-progress',saleId,'action','salesAdvice'])
        return result.response
    }


    removeFile_ = async (fileName)=>{
        const result = this.updateSaleInProgressDetail_({
            [this.uploadingConfig[fileName].fieldName]:null
        })
        this.setPipelineFiles({
            ...this.pipelineFiles,
            [fileName]:null
        })
        return result.response
    }

    reloadingFiles_ = async (saleId=this.pipelineData.id)=>{
        const {response} = await this.appStore.client.get(['sales-in-progress',saleId])
        console.log(response)
        let files = {}
        Object.keys(this.uploadingConfig).map(key=>({fieldName:this.uploadingConfig[key].fieldName,key})).forEach(item=>{
            files[item.key] = response[item.fieldName]
        })
        this.setPipelineFiles(files)
        return files
    }

    @observable savingReservationData = false
    @action setSavingReservationData = value=>this.savingReservationData = value


    onSaveOptionalDepositData_ = async () =>{
        await this.updateSaleInProgressDetail_({
            depositedAmount : numeral(this.depositAmount)._value,
            depositedDate : this.depositDate
        })
    }

    onSaveUnderReservationData_ = async ()=>{
        this.setSavingReservationData(true)
        await this.onSavePurchasers_()
        await this.onSaveForm_()
        await this.onSaveDeposit_()
        await this.onSaveOptionalDepositData_()
        await this.reloadPipelineData_()
        this.setSavingReservationData(false)
    }

    @observable savingPendingExchangeData = false
    @action setSavingPendingExchangeData = value=>this.savingPendingExchangeData = value

    onSavePendingExchangeData_ = async ()=>{
        this.setSavingPendingExchangeData(true)
        await this.onSavePendingExchangeFiles_()
        await this.reloadPipelineData_()
        this.setSavingPendingExchangeData(false)
    }

    refreshingGeneratedFile_ = async (saleId,fileName)=>{
        const maxCount = 4
        let currentCount = 0
        while(currentCount<maxCount){
            currentCount++
            const {response} = await this.appStore.client.get(['sales-in-progress',saleId])
            console.log(response)
            if(response[this.uploadingConfig[fileName].fieldName]){
                return response[this.uploadingConfig[fileName].fieldName]
            }
            await this.sleep(5000)
        }
        return null
    }

    generateCommissionInvoice_ = async (saleId=this.pipelineData.id)=>{
        const result = await this.appStore.client.put(['sales-in-progress',saleId,'action','commissionInvoice'])
        return result.response
    }

    saleInProgressSubmit_ = async (saleId=this.pipelineData.id)=>{
        const result = await this.appStore.client.put(['sales-in-progress',saleId,'action','submitRegistration'])
        return result.response
    }

    @computed get pipelineSubmittable(){
        // return {
        //     underReservation:(this.fillOnline?this.registrationFormReady : this.pipelineFiles?.['registrationForm']) && this.pipelineFiles?.['depositReceipt'],
        //     pendingExchange:this.pipelineFiles.exchangeDepositReceipt && this.pipelineFiles.frontPage && this.pipelineFiles.signedPage && this.pipelineFiles['66W'],
        //     pendingSettlement:this.pipelineFiles.orderOnAgent && this.pipelineFiles.settledCommissionInvoice,
        // }
        return {
            //underReservation
            0 : (this.fillOnline?this.registrationFormReady : this.pipelineFiles?.['registrationForm']) && this.pipelineFiles?.['depositReceipt'],
            //( this.fillOnline && this.registrationFormReady) || (!this.fillOnline && this.pipelineFiles?.['registrationForm'] && this.pipelineFiles?.['depositReceipt']),
            //pendingExchange 
            2 :  (this.pipelineFiles.exchangeDepositReceipt && this.pipelineFiles.frontPage && this.pipelineFiles.signedPage && this.pipelineFiles['66W'] ) ? true :false,
            //pendingSettlement
            3 : (this.pipelineFiles.orderOnAgent && this.pipelineFiles.settledCommissionInvoice) ? true :false
        }
    }

    clearRefundForm =()=>{
        this.setRefundReference('')
        this.setRefundReason('')
        this.setRefundBankName('')
        this.setRefundAccountName('')
        this.setRefundAccountBsb('')
        this.setRefundAccountNumber('')
    }



};

export default PipelineStore;