import { configure } from 'mobx';
import AppStore from './AppStore'
import GeneralInspectionStore from './GeneralStore/InspectionStore';
import GeneralPropertyStore from './GeneralStore/PropertyStore';
import MapStore from './GeneralStore/MapStore'
import FilterStore from './GeneralStore/FilterStore'
import UiStore from './GeneralStore/UiStore'
import PropertyStore from './PropertyStore';
import CollectionStore from './CollectionStore';
import InspectionStore from './InspectionStore'
import DealStore from './GeneralStore/DealStore'
import PipelineStore from './PipelineStore'
// don't allow state modifications outside actions
configure({ enforceActions: "always" });
const appStore = new AppStore()
const uiStore = new UiStore(appStore)
const generalInspectionStore = new GeneralInspectionStore()
const inspectionStore = new InspectionStore(appStore, uiStore, generalInspectionStore)
const generalPropertyStore = new GeneralPropertyStore(generalInspectionStore)
const filterStore = new FilterStore(appStore,uiStore)
const mapStore = new MapStore(appStore,generalInspectionStore)
// const propertyStore = new PropertyStore(generalPropertyStore,mapStore)
const propertyStore = new PropertyStore(appStore,generalPropertyStore,mapStore,filterStore,uiStore)
const collectionStore = new CollectionStore(appStore, uiStore, propertyStore, filterStore)
const dealStore = new DealStore(appStore)
const pipelineStore = new PipelineStore(appStore)


export {
    appStore,
    generalInspectionStore,
    inspectionStore,
    generalPropertyStore,
    propertyStore,
    mapStore,
    uiStore,
    filterStore,
    collectionStore,
    dealStore,
    pipelineStore
};