import {observable, action, computed} from 'mobx';
import _ from 'lodash'
import {clone} from '@src/utils/typeCheck'

class CollectionStore {
    constructor(appStore, uiStore, propertyStore, filterStore) {
        this.appStore = appStore;
        this.uiStore = uiStore;
        this.propertyStore = propertyStore
        this.filterStore = filterStore
    }

    @observable collectionList = []
    @action setCollectionList = value=>this.collectionList = value  

    initCollectionList_ = async (type='buy')=>{
        this.uiStore.setIsBackgroundDataLoading(true)
        try {
            this.getCollectionsTabNumber_()
            const res = await this.appStore.clientApi.getCollectionByType_(type)
            // const test = await this.appStore.clientApi.getCollectionWithFilter_({
            //     type,
            //     cluster:true
            // })
            // console.log(test)
            const detailsRes = await Promise.all(res.items.map(item=>this.appStore.clientApi.getCollectionDetail_(item.listing)))
            const processedRes = this.propertyStore.generalPropertyStore.processListData(res.items).map(item=>this.propertyStore.additionalProcess(item)).map((item,index)=>({
                ...item,
                collectionDetails:detailsRes[index],
            }))
            if(this.uiStore.isCollectionView){
                this.uiStore.setMapLoadingDataNum(processedRes.length)
            }
            this.setCollectionList(processedRes)
            console.log('collections',type,processedRes)
            this.uiStore.setIsBackgroundDataLoading(false)
        } catch (error) {
            console.log('map fetching error',error)
            this.uiStore.setMapLoadingDataNum(0)
        }finally{
            this.uiStore.setIsBackgroundDataLoading(false)
        }
    }

    @observable collectionNum = {
        buy:null,
        rent:null,
        gone:null
    }

    @action setCollectionNum = value=>this.collectionNum = value

    getCollectionsTabNumber_ = async ()=>{
        const types = ['buy','rent','gone']
        const res = await Promise.all(types.map(type=>this.appStore.clientApi.getCollectionByType_(type)))
        this.setCollectionNum({
            buy:res[0].totalItems,
            rent:res[1].totalItems,
            gone:res[2].totalItems
        }) 
    }

    removeFavor = (listingId)=>{
        this.propertyStore.unfavorProperty_(listingId)
        this.propertyStore.toggleFavorByListingId(false,listingId)

        let cp = clone(this.collectionList)
        const index = _.findIndex(cp,o=>o.rawData.listing===listingId)
        console.log(index)
        if(index!==undefined){
            cp.splice(index,1)
            this.setCollectionList(cp)
            this.getCollectionsTabNumber_()
        }
    }

    addFavor_ = async (listingId)=>{
        await this.propertyStore.favorProperty_(listingId)
        this.initCollectionList_(this.filterStore.isRentFilter?'rent':'buy')
        this.getCollectionsTabNumber_()
    }

    updateNote_ = async (listingId,note)=>{
        const res = await this.appStore.clientApi.addNoteForCollection_(listingId,note)
        return res
    }

    updatePriceChangeNotification_ = async (listingId,priceChangeNotify)=>{
        const res = await this.appStore.clientApi.setPriceChangeNotification_(listingId,priceChangeNotify)
        return res
    }

    updateCollectionDetailsData = (listingId,priceChangeNotify,note)=>{
        this.setCollectionList(this.collectionList.map(item=>{
            if(item.processedData.listingId === listingId){
                return {
                    ...item,
                    collectionDetails:{
                        ...item.collectionDetails,
                        note,
                        priceChangeNotify
                    }
                }
            }else{
                return item
            }
        }))

    }

    saveSearch_ = async(data)=>{
        const res = await this.appStore.clientApi.addCustomData_('saved-search',data)
        return res
    }

}

export default CollectionStore;
