import { observable, action, computed, autorun } from 'mobx';
import { add, format, parseISO, addMinutes, isToday , getDay , getTime , addDays  , getMilliseconds,startOfToday  } from "date-fns";
import _ from 'lodash'
import { DateTime } from 'luxon'
import moment from 'moment';
class InspectionStore {
    constructor() { 
    }
    
    getFormatDateAndTime = (dateObj)=>{
        const str = format(parseISO(dateObj) , 'yyyy-MM-dd|t')
        return str.split('|')
    }

    getOpenInspectionDisplayTimeString = (dateObj)=>{
        const dt = format(parseISO(dateObj), 'ccc d LLLL') 
        return dt; 
    }

    minutesToTime = (minutes)=>{
        var d = new Date();
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
        return addMinutes(d, minutes)
    }


    getOpenInspectionTimeRangeDisplayTimeString = (startTime,endTime)=>{
        var start = format(this.minutesToTime(startTime),'h:mm a')
        var end = format(this.minutesToTime(endTime),'h:mm a')
        return ` ${start} - ${end}`
    }

    
    generateOpenInspectionTimeList = (rawData)=>{
        let list = []
        // console.log(rawData)
        const now = moment();
        rawData.forEach(timeSetting=>{
            if(timeSetting.date){
                const _startDate = moment(timeSetting.date).format('YYYY-MM-DD');
                const _startTime = format(this.minutesToTime(timeSetting.startTime),'HH:mm');
                const _startDateTime = moment(_startDate + ' ' + _startTime);

                const dateObj = new Date(Date.parse(timeSetting.date))
                if(now.isBefore(_startDateTime)){
                    list.push({
                        date:this.getOpenInspectionDisplayTimeString(dateObj.toISOString()),
                        time:this.getOpenInspectionTimeRangeDisplayTimeString(timeSetting.startTime,timeSetting.endTime),
                        details:{
                            date:format(parseISO(dateObj.toISOString()),'yyyy-MM-dd'),
                            ISODateString:dateObj.toISOString(),
                            range:{
                                start:format(this.minutesToTime(timeSetting.startTime),'h:mm a'),
                                end:format(this.minutesToTime(timeSetting.endTime),'h:mm a')
                            }
                        },
                        raw:timeSetting
                    })
                }

            }else{
                const {
                    id,
                    repeatType,
                    startDate,
                    endDate,
                    startTime,
                    endTime,
                } = timeSetting;
    
                const _startDate = moment(startDate).format('YYYY-MM-DD');
                const _endDate = moment(endDate);
                const _startTime = format(this.minutesToTime(startTime),'HH:mm');
                const _startDateTime = moment(_startDate + ' ' + _startTime);
                console.log(id, '_startDateTime', _startDateTime, startDate);
            
                let increment = null;
                switch (repeatType) {
                    case 'daily':
                        increment = 'day';
                        break;
                    case 'weekly':
                        increment = 'week';
                        break;
                    case 'monthly':
                        increment = 'month';
                        break;
                    default: 
                        console.log('No-ops');
                }

                if (increment) {
                    let current = _startDateTime.clone();
                    if (!current.isBetween(_startDateTime, _endDate) || now.isAfter(_startDateTime)) {
                        current.add(1, increment);
                    }

                    while (current.isBetween(_startDateTime, _endDate)) {
                        // console.log('while loop', increment, current.format('LLL'), _endDate.format('LLL'));
                        list.push({
                            date:this.getOpenInspectionDisplayTimeString(current.toISOString()),
                            time:this.getOpenInspectionTimeRangeDisplayTimeString(startTime,endTime),
                            details:{
                                date: format(parseISO(current.toISOString()),'yyyy-MM-dd'),
                                ISODateString: current.toISOString(),
                                range:{
                                    start: format(this.minutesToTime(startTime),'h:mm a'),
                                    end:format(this.minutesToTime(endTime) ,'h:mm a')
                                }
                            },
                            raw:timeSetting
                        });
                        current.add(1, increment);
                    }
                }

                // const weeks = _.sortBy(_.split(timeSetting.dayOfWeek,',').map(v=>Number(v)),[v=>v])
                // const weeks = [1,2,3,4,5,6,7]
                // const startDate = new Date(Date.parse(timeSetting.startDate))
                // const startDateWeekDay = getDay(startDate)===0?7:getDay(startDate)
                // let dateList = []
                // let inWeekIndex = _.findIndex(weeks,v=>v >= startDateWeekDay)
                // if(inWeekIndex!==-1){
                //     for (let index = 0; index <= inWeekIndex; index++) {
                //         const newDay = addDays(startDate,7 - startDateWeekDay + weeks[index])
                //          dateList.push(newDay)
                //     }
                //     while (inWeekIndex < weeks.length - 1) {
                //         const newDay = addDays(startDate, weeks[inWeekIndex]- startDateWeekDay)
                //         dateList.push(newDay)
                //         inWeekIndex+=1
                //     }
                // }else{
                //     weeks.forEach(weekDay=>{
                //         const newDay = addDays(startDate, 7 - startDateWeekDay + weekDay)
                //         dateList.push(newDay)
                //     })
                // }
                // for (let time = 0; time < timeSetting.repeatTimes; time++) {
                //     dateList.forEach(o=>{
                //         const dateObj = addDays(o,7 * time)
                //         if(localMillis < getTime(dateObj)){
                //             list.push({
                //                 date:this.getOpenInspectionDisplayTimeString(dateObj.toISOString()),
                //                 time:this.getOpenInspectionTimeRangeDisplayTimeString(timeSetting.startTime,timeSetting.endTime),
                //                 details:{
                //                     date: format(parseISO(dateObj.toISOString()),'yyyy-MM-dd'),
                //                     ISODateString: dateObj.toISOString(),
                //                     range:{
                //                         start: format(this.minutesToTime(timeSetting.startTime),'h:mm a'),
                //                         end:format(this.minutesToTime(timeSetting.endTime) ,'h:mm a')
                //                     }
                //                 },
                //                 raw:timeSetting
                //             })
                //         }
                //     })
                // }
            }
        })
        const sorted = _.sortBy(list,o=>o.details.ISODateString)
        return sorted
    }


}

export default InspectionStore;