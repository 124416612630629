import { observable, action, computed, autorun } from 'mobx';
import { DateTime } from 'luxon'
import { clone } from '@src/utils/typeCheck';
import _ from 'lodash'
class InspectionStore {
    constructor(appStore, uiStore, generalInspectionStore) {
        this.appStore = appStore;
        this.uiStore = uiStore;
        this.generalInspectionStore = generalInspectionStore;
    }
    
    getInspectionTime_ = async (listingId)=>{
        const res = await this.appStore.clientApi.getPropertyOpenInspection_(listingId)
        return this.generalInspectionStore.generateOpenInspectionTimeList(res)
    }

    bookPrivateInspection_ = async (listingId,dateTimeISO,startTimeISO,endTimeISO)=>{
        const dt = DateTime.fromISO(dateTimeISO)
        const startDt = DateTime.fromISO(startTimeISO)
        const endDt = DateTime.fromISO(endTimeISO)
        const startMinutes = startDt.minute + startDt.hour * 60
        const endMinutes = endDt.minute + endDt.hour * 60
        const data = {
            "date":dt.toFormat('yyyy-MM-dd'),
            "startTime": startMinutes,
            "endTime": endMinutes
        }
        const res = await this.appStore.clientApi.bookPrivateInspection_(listingId,data)
        console.log('bookPrivateInspection_',res)
        this.uiStore.addNotification({message:'Private inspection has been added in your calender.'})
        return res
    }
    

    addOpenInspection_ = async (listingId,dateTimeISO,openInspectionId)=>{
        const dt = DateTime.fromISO(dateTimeISO)
        const startMinutes = dt.minute + dt.hour * 60
        const data = {
            "date":dt.toFormat('yyyy-MM-dd'),
            "startTime": startMinutes,
            "endTime": startMinutes + 30,
            "openInspection": openInspectionId
        }
        const res = await this.appStore.clientApi.addOpenInspection_(listingId,data)
        console.log('addOpenInspection_',res)
        this.uiStore.addNotification({message:'Public inspection has been added in your calender.'})
        return res
    }

    addOpenInspectionFromDetails = (details, listingId ,openInspectionId)=>{
        const isoTime = DateTime.fromFormat(`${details.date}-${details.range.start}`,'yyyy-MM-dd-t').toISO()
        this.addOpenInspection_(listingId,isoTime,openInspectionId)
    }

    processFakeDateTime = (date,time)=>{
        return DateTime.fromFormat(`${date}-${time}`,'yyyy-MM-dd-t')
    }


    @observable inspections = {}
    @action setInspections = (inspections)=>this.inspections = inspections
    
    maxId = 7
    setMaxId = (n)=>this.maxId = n

    reloadAndSetNewInspection_ = (inspections)=>{
        //fake reload
        this.setInspections({})
        setTimeout(() => {
            this.setInspections(inspections)
        }, 1000);
    }
    reloadInspection_ = ()=>{
        const save = this.inspections
        this.setInspections({})

        setTimeout(() => {
            this.setInspections(save)
        }, 100);
    }

    deleteInspection = (item) =>{
        let newInspections = clone(this.inspections)
        let removed = []
        Object.keys(this.inspections).forEach(key=>{
            const index = _.findIndex(this.inspections[key],item)
            if(index!== -1){
                removed = _.remove(newInspections[key],n=>n.id===item.id)

                if(newInspections[key].length===0){
                    newInspections = _.omit(newInspections,[key])
                }

                this.reloadAndSetNewInspection_(newInspections)

                //console.log(11,newInspections,removed)
                this.appStore.clientApi.deleteAgenda_(item.id)

                return
            }
        })
    }

    changeInspectionTime = (newISODateTime,item) => {
        let newInspections = clone(this.inspections)
        Object.keys(this.inspections).forEach(key=>{
            const index = _.findIndex(this.inspections[key],item)
            if(index!== -1){
                const oldDateTime = DateTime.fromISO(item.dateTime)
                const newDateTime = DateTime.fromISO(newISODateTime)
                if(oldDateTime.equals(newDateTime)){
                    return
                }else{
                    const [dateKey,time] = this.generalInspectionStore.getFormatDateAndTime(newDateTime)
                    const newInspection = {
                        id: this.maxId++, 
                        note:item.note, 
                        dateTime: newISODateTime,
                        key:dateKey,
                        availableTimeRange:item.availableTimeRange
                    }
                    const validateRes = this.validateChangeInspectionTime(newInspection,item)
                    if(validateRes.validate){
                        const removed = _.remove(newInspections[key],n=>n.id===item.id)
                        console.log('removed',removed)
                        // create new inspection date
                        // omit date in map
                        if(dateKey!==key && newInspections[key].length===0){
                            newInspections = _.omit(newInspections,[key])
                        }
                        if(!_.has(newInspections,dateKey)){
                            newInspections[dateKey]=[]
                        }
                        newInspections[dateKey].push(newInspection)
                        newInspections[dateKey] = _.sortBy(newInspections[dateKey],o=>DateTime.fromISO(o.dateTime))
                        console.log('added',newInspections[dateKey])
                        this.reloadAndSetNewInspection_(newInspections)
                        return 
                    }else{
                        this.uiStore.addNotification(validateRes.msg)
                    }
                }
            }
        })
    }

    editNote=(text,item)=>{
        let newInspections = clone(this.inspections)
        Object.keys(this.inspections).forEach(key=>{
            const index = _.findIndex(this.inspections[key],item)
            if(index!== -1){
                newInspections[key][index].note = text
                this.reloadAndSetNewInspection_(newInspections)
                console.log('setNote')
                return
            }
        })
    }

    addNewInspection = ({ dateTime,note='',availableTimeRange }) => {
        let newInspections = clone(this.inspections)
        const [dateKey,time] = this.getFormatDateAndTime(dateTime)
        if(!_.has(newInspections,dateKey)){
            newInspections[dateKey]=[]
        }
        const newInspection = {
            id: this.maxId++, 
            note: note, 
            dateTime: dateTime,
            key:dateKey,
            availableTimeRange:availableTimeRange
        }
        const validateRes = this.validateAddInspection(newInspection)
        if(validateRes.validate){
            newInspections[dateKey].push(newInspection)
            newInspections[dateKey] = _.sortBy(newInspections[dateKey],o=>DateTime.fromISO(o.dateTime))
            this.reloadAndSetNewInspection_(newInspections)
            console.log('added',newInspections)
        }else{
            this.uiStore.addNotification(validateRes.msg)
        }
    }

    validateAddInspection = (inspectionItem)=>{
        const { id, dateTime, note, availableTimeRange, key, propertyId=-1 } = inspectionItem
        // async check property Id is available in Server
        // use local date array as example
        const dayResult = this.inspections[key]
        if(_.find(dayResult,{propertyId:propertyId})){
            return {validate:false,msg:'Exist same day inspection'}
        }else{
            return {validate:true,msg:'validate'}
        }
    }
    validateChangeInspectionTime = (newInspectionItem,oldInspectionItem)=>{
        if(newInspectionItem.key===oldInspectionItem.key){
            return {validate:true,msg:'Same day change'}
        }else{
            // async validate 
            return this.validateAddInspection(newInspectionItem)
        }
        

    }
}

export default InspectionStore;