class Polygon {
    /**
     * Constructor
     * @param {Array} path - Array of Points
     */
    constructor(path) {
        this.path = path;
    }

    get getPath() {
        return this.path;
    }

    /**
     * Check whether a point crosses the ray a-b.
     * 
     * @param {Object} point - Point to check if it cross.
     * @param {Object} a - Start point of the ray.
     * @param {Object} b - End point of the ray.
     * @returns {Boolean}
     */
    rayCrossesSegment(point, a, b) {
        let px = point.longitude,
            py = point.latitude,
            ax = a.longitude,
            ay = a.latitude,
            bx = b.longitude,
            by = b.latitude;
        if (ay > by) {
            ax = b.longitude;
            ay = b.latitude;
            bx = a.longitude;
            by = a.latitude;
        }
        // alter longitude to cater for 180 degree crossings
        if (px < 0) {
            px += 360;
        }
        if (ax < 0) {
            ax += 360;
        }
        if (bx < 0) {
            bx += 360;
        }

        if (py == ay || py == by) py += 0.00000001;
        if ((py > by || py < ay) || (px > Math.max(ax, bx))) return false;
        if (px < Math.min(ax, bx)) return true;

        let red = (ax != bx) ? ((by - ay) / (bx - ax)) : Infinity;
        let blue = (ax != px) ? ((py - ay) / (px - ax)) : Infinity;
        return (blue >= red);
    }

    /**
     * Use ray casting algorithm to check if given point is contains in the polygon.
     * 
     * @param {Object} point - Point to check if contains.
     * @returns 
     */
    contains(point) {
        let crossings = 0;
        for (let i = 0; i < this.path.length; i++) {
            const a = this.path[i];
            const j = i + 1;
            if (j >= this.path.length) {
                j = 0;
            }
            const b = this.path[j];
            if (this.rayCrossesSegment(point, a, b)) {
                crossings++;
            }
        }

        return (crossings % 2 === 1);
    }
}

export {
    Polygon
}